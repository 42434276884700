import React, { Fragment, useContext, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Layout, Seo } from 'containers';
import uniqBy from 'lodash/uniqBy';
import { capitalizeEach, dashName, replaceDashWithEmpty } from 'utilities';
import { NavigationContext } from 'context/navigationContext';

const DentistList = ({ citiesByState, state }) => (
	<ul className='list-style-none'>
		{citiesByState.map((item) => (
			<li key={item} className='py-xs'>
				<Link to={`/emergency-dentist/${dashName(state)}/${dashName(item)}`}>
					{capitalizeEach(replaceDashWithEmpty(item))}
				</Link>
			</li>
		))}
	</ul>
);

const DentistUnit = ({ data }) => {
	const uniqStates = uniqBy(data.allCitiesJson.nodes, 'state_long')
		.map((state) => state.state_long)
		.sort();

	return uniqStates.map((state, idx) => {
		const citiesByState = data.allCitiesJson.nodes
			.filter((city) => city.state_long === state)
			?.map((el) => el.city);

		return (
			<Fragment key={idx}>
				<h3>{capitalizeEach(replaceDashWithEmpty(state))}</h3>
				<DentistList citiesByState={citiesByState} state={state} />
			</Fragment>
		);
	});
};

const BrowseDentist = ({ data, pageContext }) => {
	const mainNavigation = pageContext.mainNavigation.items;
	const { setNavItems } = useContext(NavigationContext);

	useEffect(() => {
		setNavItems(mainNavigation);
	}, []);

	return (
		<Layout type='post'>
			<Seo
				title='Browse dentists by state | Authority Dental'
				description='Browse dentists on Authority Dental in your city by specialty, expertise, and preferred payment method.'
			/>
			<article className='without-toc'>
				<main className='post-container'>
					<div className='post-content'>
						<h1>Browse dentists by state</h1>
						<p>
							If your city is not on the list, please give us a call at{' '}
							<a href='tel:18884880618'>1-888-488-0618</a> and enter your ZIP code. In 99% of cases, we
							will be able to help you.
						</p>
						<DentistUnit data={data} />
					</div>
				</main>
			</article>
		</Layout>
	);
};

export default BrowseDentist;

export const query = graphql`
	query {
		allCitiesJson {
			nodes {
				city
				state_long
				state_short
			}
		}
	}
`;
